import React, { useEffect } from "react";
import "./pricing.scss";
import { useNavigate, useSearchParams } from "react-router-dom";
import { myRequest } from "../lib";
import { notification } from "antd";
import Loader from "../../components/loader";

const PricingX = () => {
  const navigate = useNavigate();
  const [queryParams, _] = useSearchParams() //eslint-disable-line

  useEffect(() => {
    const planId = queryParams.get('plan')
    if(!planId) navigate('/pricing')

    myRequest("post", "orgPlansPublic")
      .then((data) => data?.data)
      .then((data) => {
        if (data?.error) {
          notification.error({ message: data?.message });
          navigate('/pricing')
        } else {
          for(let i = 0; i < data?.data?.length; i++){
              const plan = data?.data[i]
              if(plan['plan_id'] === parseInt(planId)){
                navigate('/register', {state: {
                  option: plan.plan_name,
                  price: plan.plan_cost,
                  sixPrice: plan.plan_six_cost,
                  category: "self pay",
                }})
                return;
              }
          }
          navigate('/pricing')
        }
      })
  }, [queryParams, navigate]);

  return (<>
    <Loader size={200}/>
  </>);
};

export default PricingX;
