import { Card, Col, Divider, List, Row, Spin, Typography } from 'antd';
import { BsCheck2All } from "react-icons/bs";
import {useNavigate} from 'react-router-dom';

import './actionSelect.scss';

const { Meta } = Card;

const ActionSelect = () => {
    const navigate = useNavigate();

    return (
        <div className="actionselect" >
            <h2>Aman HMO</h2>
            <div>
                <OptionButton text={"Facility"} />
                <OptionButton text={"Provider"} />
                <OptionButton onClick={()=> navigate('/pricing')} text={"Patient"} />
            </div>
        </div>
    )
}

export default ActionSelect



export const OptionButton = (props) => {
    return (
        <div className='optionButton' onClick={props.onClick}>
            {props.text}
        </div>
    )
}



export const SelectButton = (props) => {
    return (
        <div className='selectButton' onClick={props.onClick}>
            {props.text}
        </div>
    )
}

export const PackageCards = props => {
    const navigate = useNavigate();

    return (
        <div className="site-card-wrapper">
            {props?.loading && <Spin size='large' style={{fontSize: 200, display: 'flex', placeSelf: 'center', }}/>}
            <Row gutter={[16, 16]} align='stretch' justify='center'>
                {
                    props.data?.map((pkg, index) => 
                        <Col span={10} xs={24} sm={24} md={24} lg={12} xl={12}>
                            <Card bordered={true} hoverable style={{height: '100%'}} onClick={() => navigate('/register', {state: pkg.state})}>
                                <Meta
                                    // avatar={<Avatar src="https://joeschmoe.io/api/v1/random" />}
                                    title={pkg.name}
                                    description={pkg.title}
                                />
                                <Divider style={{margin: '5px 0'}}/>
                                <List
                                    className="demo-loadmore-list"
                                    itemLayout="horizontal"
                                    dataSource={pkg.benefits}
                                    renderItem={(item) => (
                                        <Typography.Paragraph style={{padding: '2px 0', margin: 0}}>
                                            <BsCheck2All />&nbsp; &nbsp; <span>{item}</span>
                                        </Typography.Paragraph>
                                    )}
                                />
                            </Card>
                        </Col>
                    )
                }
            </Row>
        </div>
    )
}


