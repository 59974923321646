// import logoText from '../assets/images/claima_stacked_black.png';
import logoText from '../assets/images/icon/amanhmo.svg';

const LogoHook = props => {
    let style = {
      width: '100px',
      // width: '120px',
      height: '70px',
      // height: '120px',
    }
  
    return(
      <>
        <div style={{margin: '0 0 30px 0'}}>
          <img src={logoText}  style={style} alt='Claima' />
        </div>
      </>
    )
}

export default LogoHook;