import config from '../assets/utils/config';

import Overview from './overview/index';
import Pricing from './pricing/index';
import Login from './login/index';
// import Logout from './logout/index';
import Register from './register/register';
import Reset from './reset/index';
import PreDashboard from './preDashboard/index';
// import PricingX from './pricing/pricingX';

// Access 1 - super admin, 2 - admin staff & support
const pages = config.pages;

export const routes = [
    {link: pages.overview, Component: Overview },
    {link: pages.pricing, Component: Pricing },
    // {link: pages.pricingX, Component: PricingX },
    {link: pages.login, Component: Login },
    // {link: pages.logout, Component: Logout },
    {link: pages.register, Component: Register },
    {link: pages.reset, Component: Reset },
    {link: pages.predashboard, Component: PreDashboard },    
] 

// export const routes = [
//     {link: pages.overview, Component: Overview },
//     // {link: pages.pricing, Component: Login },
//     {link: pages.login, Component: Login },
//     {link: pages.register, Component: Register },
//     {link: pages.predashboard, Component: PreDashboard }, 
// ] 