// 

const config = { 
    pages: {
        overview: '/overview',
        pricing: '/pricing',
        login: '/login',
        register: '/register',
        reset: '/reset/',
        predashboard: '/predashboard',
    },
    key: {
        user: '___@amad_user',
        token: '___@amad_token',
        paymentStatus: 'paymentStatus'
    },
    paymentApi: {
        public: 'FLWPUBK-2ff657dbfa82fab6353bac0c95151392-X'
    }
};

export const states = [
    { label: "Abia", value: "abia" },
    { label: "Adamawa", value: "adamawa" },
    { label: "Akwa Ibom", value: "akwa ibom" },
    { label: "Anambra", value: "anambra" },
    { label: "Bauchi", value: "bauchi" },
    { label: "Bayelsa", value: "bayelsa" },
    { label: "Benue", value: "benue" },
    { label: "Borno", value: "borno" },
    { label: "Cross River", value: "cross river" },
    { label: "Delta", value: "delta" },
    { label: "Ebonyi", value: "ebonyi" },
    { label: "Edo", value: "edo" },
    { label: "Ekiti", value: "ekiti" },
    { label: "Enugu", value: "enugu" },
    { label: "FCT", value: "abuja" },
    { label: "Gombe", value: "gombe" },
    { label: "Imo", value: "imo" },
    { label: "Jigawa", value: "jigawa" },
    { label: "Kaduna", value: "kaduna" },
    { label: "Kano", value: "kano" },
    { label: "Katsina", value: "kastina" },
    { label: "Kebbi", value: "kebbi" },
    { label: "Kogi", value: "kogi" },
    { label: "Kwara", value: "kwara" },
    { label: "Lagos", value: "lagos" },
    { label: "Nasarawa", value: "nasarawa" },
    { label: "Niger", value: "niger" },
    { label: "Ogun", value: "ogun" },
    { label: "Ondo", value: "ondo" },
    { label: "Osun", value: "osun" },
    { label: "Oyo", value: "oyo" },
    { label: "Plateau", value: "plateau" },
    { label: "Rivers", value: "rivers" },
    { label: "Sokoto", value: "sokot" },
    { label: "Taraba", value: "jalingo" },
    { label: "Yobe", value: "yobe" },
    { label: "Zamfara", value: "zamfara" },
  ];
  
  export const packages = [
    { label: "Basic", value: "basic" },
    { label: "Bronze", value: "bronze" },
    { label: "Silver", value: "silver" },
    { label: "Gold", value: "gold" },
    { label: "Platinum", value: "platinum" },
  ];
  
  export const gender = [
    { label: "Male", value: "male" },
    { label: "Female", value: "female" },
  ];
  
  export const role = [
    { label: "Dependent", value: "dependent" },
    { label: "Principal", value: "principal" },
  ];
  
  export const policy_type = [
    { label: "Family", value: "family" },
    { label: "Individual", value: "individual" },
  ];
  
  export const title = [
    { label: "Mr.", value: "Mr" },
    { label: "Mrs.", value: "Mrs" },
    { label: "Miss", value: "Miss" },
    { label: "Dr.", value: "Dr" },
    { label: "Prof.", value: "Prof" },
    { label: "Engr.", value: "Engr." },
  ];
  
  export const facilityType = [
    { label: "Dental", value: "dental" },
    { label: "Hospital", value: "hospital" },
    { label: "Radiology", value: "Radiology" },
    { label: "Gymnasium", value: "gymnasium" },
    { label: "Laboratory", value: "laboratory" },
    { label: "Darmatology", value: "damatology" },
  ];
  
  export const packagePrices = {
    basic: {
      individual: 18000.0,
      family: 18000.0,
    },
    bronze: {
      individual: 35784.0,
      family: 136122.0,
    },
    silver: {
      individual: 55394.0,
      family: 203406.0,
    },
    gold: {
      individual: 96066.0,
      family: 356365.0,
    },
    platinum: {
      individual: 145836.0,
      family: 534930.0,
    },
  };
  
  export const category = [
    { label: "Corporate", value: "corporate" },
    { label: "Self-Paid", value: "self pay" },
  ];
  
  export const searchTarget = [
    { label: "Clients", value: "clients" },
    { label: "Facilities", value: "facilities" },
  ];
  
  export const maritalStatus = [
    { label: "Spouse", value: "spouse" },
    { label: "Child", value: "child" },
    { label: "Other", value: "other" },
  ];


export default config;