import React from 'react';
import './index.css'; // Import CSS for styling

const Loader = ({ size = 50, color = '#3498db' }) => {
  const loaderStyle = {
    width: size,
    height: size,
    borderTopColor: color,
  };

  return (
    <div className="loader-container">
      <div className="loader" style={loaderStyle}></div>
    </div>
  );
};

export default Loader;
